import { Booking, Heart, SearchNew, User } from "common/Icons/Icons";

import { FC } from "react";
import Link from "next/link";
import styles from "./styles.module.scss";
import { useAuth } from "services/AuthService/AuthProvider";

const getNavigationItems = (isAuthenticated: boolean) => [
  {
    name: "Explore",
    path: "/search",
    icon: <SearchNew />,
    allow: "both",
  },
  {
    name: "Wish List",
    path: "/profile/my-wish-list",
    icon: <Heart />,
    allow: "both",
  },
  {
    name: "Bookings",
    path: "/profile/my-bookings",
    icon: <Booking />,
    allow: "private",
  },
  isAuthenticated
    ? {
        name: "Profile",
        path: "/profile/my-profile",
        icon: <User />,
        allow: "private",
      }
    : {
        name: "Login",
        path: "/login",
        icon: <User />,
        allow: "public",
      },
];

const BottomNav: FC = () => {
  const { isAuthenticated } = useAuth();
  const navigationData = getNavigationItems(isAuthenticated);

  return (
    <nav className={`${styles.bottomNavigation} d-md-none d-block`}>
      <div className="d-flex flex-row align-items-center justify-content-around">
        {navigationData.map(({ name, path, icon }) => (
          <div key={name}>
            <Link href={path}>
              <a>
                {icon}
                <div className="my-1" />
                <span>{name}</span>
              </a>
            </Link>
          </div>
        ))}
      </div>
    </nav>
  );
};

export default BottomNav;
