import Utils from "utils/Utils";
import styles from "./ImageOverlayCard.module.scss";

const ImageOverlayCard = ({ item: { name, seo_image, slug } = {} }) => (
  <div className={styles["image-overlay-card"]} key={slug}>
    <picture>
      <source
        className={styles["cover-image"]}
        srcSet={Utils.constructImageUrl("600x400", "webp", seo_image)}
        type="image/webp"
      />
      <source
        className={styles["cover-image"]}
        srcSet={Utils.constructImageUrl("600x400", "jpg", seo_image)}
        type="image/jpeg"
      />
      <img
        className={styles["cover-image"]}
        loading={"lazy"}
        src={Utils.constructImageUrl("600x400", "jpeg", seo_image)}
        alt={name}
      />
    </picture>
    <div className={styles["overlay-div"]}>
      <h1>{name}</h1>
    </div>
  </div>
);

export default ImageOverlayCard;
