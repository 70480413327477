export const ONLINE_EXPERIENCES_INSIGHTS = [
  {
    insight: "10,000+",
    icon: "Users Served",
  },
  {
    insight: "200+",
    icon: "Experiences Added",
  },
  {
    insight: "200,000+",
    icon: "Total Live Hours",
  },
  {
    insight: "4.9",
    icon: "Average Rating",
  },
];
export const OFFLINE_EXPERIENCES_INSIGHTS = [
  {
    insight: "10,000+",
    icon: "Users Served",
  },
  {
    insight: "200+",
    icon: "Experiences Added",
  },
  {
    insight: "100+",
    icon: "Trips Completed",
  },
  {
    insight: "4.6",
    icon: "Average Rating",
  },
];
