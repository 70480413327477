import NumberStrip from ".";
import { NumberStripProps } from "./types";
const OnlineExperienceStrip = ({ data }: NumberStripProps) => (
  <div className="container">
    <div className=" my-5  bg-gradient-1 px-5 py-4 br-8">
      <h1 className="text-center fw-600 m-5 mx-auto text-white">
        Join one of the best <br />
        <span className="text-warning"> experiences</span> platforms of India 🚀
      </h1>
      <NumberStrip data={data} />
    </div>
  </div>
);

export default OnlineExperienceStrip;
