import { memo, useEffect, useState } from "react";
import { LandingDataType, ReviewType, getLandingData } from "./LandingUtils";

import BlogRowHolder from "common/blog/BlogRowHolder";
import BottomNav from "common/navigation/BottomNav";
import { OFFLINE_EXPERIENCES_INSIGHTS } from "common/numberStrip/constants";
import OnlineExperienceStrip from "common/numberStrip/onlineExperienceStrip";
import VideoTestimonialsStrip from "common/numberStrip/videoTestimonialsStrip";
import LazyLoad from "react-lazyload";
import { getToken } from "utils/CookieUtils";
import Cover from "./Cover";
import LandingView from "./LandingView";
import Testimonials from "./Testimonials/Testimonials";
import ValueProps from "./ValuePropsStrip/ValuePropsStrip";

const Landing: React.FC<{
  landingData: LandingDataType;
  reviews: Array<ReviewType>;
}> = ({ landingData, reviews }) => {
  const [updatedLandingData, setUpdatedLandingData] = useState(landingData);

  const getUpdatedLandingData = async () => {
    const newLandingData = (await getLandingData(
      { page: 1, limit: 8 },
      getToken()
    )) as LandingDataType;

    setUpdatedLandingData(newLandingData);
  };

  useEffect(() => {
    getUpdatedLandingData();
  }, []);

  return (
    <>
      <Cover />
      <OnlineExperienceStrip data={OFFLINE_EXPERIENCES_INSIGHTS} />

      <LandingView landingData={updatedLandingData} />

      <Testimonials reviews={reviews} />

      <LazyLoad offset={100}>
        <BlogRowHolder />
      </LazyLoad>
      <ValueProps show={false} />

      <VideoTestimonialsStrip />
      <BottomNav />
    </>
  );
};

export default memo(Landing);
