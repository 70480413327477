import CardSlider from "common/CardSlider/CardSlider";

const videoTestimonials = [
  "https://www.youtube.com/embed/90V3D7mRQyc",
  "https://www.youtube.com/embed/1mvC7emNWQY",
  "https://www.youtube.com/embed/j_z_Z_hx7Dw",
  "https://www.youtube.com/embed/Tha_lSnUxI0",
];
const VideoTestimonialsStrip = () => {
  return (
    <div className="container my-3 py-5">
      <h1 className="text-center fw-700 m-2 mx-auto">
        Don't believe in us? <br />
        <span className="text-primary">Believe</span> our amazing customers 🤩
      </h1>
      <CardSlider id={"videoTestimonial"} length={videoTestimonials.length}>
        <div className="d-flex">
          {videoTestimonials.map((videoUrl) => (
            <div key={videoUrl} className="col-md-3 col-10 px-2">
              <iframe
                className="br-8"
                width="100%"
                src={videoUrl}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          ))}
        </div>
      </CardSlider>
    </div>
  );
};
export default VideoTestimonialsStrip;
