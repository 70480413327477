import { ChevronLeft, ChevronRight } from "common/Icons/Icons";
import { FC, memo } from "react";

import ProfileImage from "common/profile/ProfileImage";
import ProfileLetter from "common/profile/ProfileLetter";
import { ReviewType } from "../LandingUtils";
import Utils from "utils/Utils";
import styles from "./Testimonials.module.scss";
import { useStateReducer } from "utils/customHooks";

const LIMIT = 3;

const Testimonials: FC<{ reviews: Array<ReviewType> }> = ({ reviews }) => {
  const [state, setState] = useStateReducer({
    selectedIndex: 0,
    page: 0,
  });

  const { selectedIndex, page } = state;

  if (Utils.arrayCheck(reviews).length === 0) return null;

  const currentSection = reviews.slice(page * LIMIT, (page + 1) * LIMIT);
  const totalPages = Math.ceil(reviews.length / LIMIT);

  const onNext = () => {
    if (page >= totalPages - 1 && selectedIndex >= currentSection.length - 1)
      return;
    if (selectedIndex < LIMIT - 1)
      setState({ selectedIndex: selectedIndex + 1 });
    else setState({ selectedIndex: 0, page: page + 1 });
  };

  const onPrev = () => {
    if (page === 0 && selectedIndex === 0) return;
    if (selectedIndex > 0) setState({ selectedIndex: selectedIndex - 1 });
    else setState({ selectedIndex: LIMIT - 1, page: page - 1 });
  };

  const selectedReview = currentSection?.[selectedIndex];

  return (
    <div className="jumbotron jumbotron-fluid mutedBgColor py-5">
      <div className="container">
        <div className={`${styles.testimonials}`}>
          <p className="mb-4 fw-700 fs-34">Our Customers Loves us</p>
          <div className="row">
            <div className="col-md-5 col-12">
              <div className="pe-5">
                <div className={`${styles.users}`}>
                  {currentSection.map((review, i) => (
                    <div
                      key={review.name}
                      className={`${styles.user} ${
                        selectedIndex === i ? styles["user-selected"] : ""
                      }`}
                      onClick={() => setState({ selectedIndex: i })}
                    >
                      {review?.imgUrl ? (
                        <ProfileImage
                          src={review?.imgUrl}
                          alt={review?.name}
                          big
                        />
                      ) : (
                        <ProfileLetter name={review?.name} big />
                      )}
                      <div className="mx-2" />
                      <div>
                        <p className="mb-0 fw-500">{review.name}</p>
                        <div>
                          {Utils.numberRange(0, review.rating).map((i) => (
                            <img
                              key={`${review.name}_rating_${i}`}
                              height={18}
                              width={18}
                              src={Utils.cdnUrl(`webImages/icons/star.svg`)}
                              alt={"rating"}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="d-flex justify-content-end mt-3">
                  <div className="indicatorBackground pointer" onClick={onPrev}>
                    <ChevronLeft
                      fill={page > 0 || selectedIndex > 0 ? "#555" : "#bbb"}
                    />
                  </div>
                  <div className="indicatorBackground pointer" onClick={onNext}>
                    <ChevronRight
                      fill={
                        page < totalPages - 1 ||
                        selectedIndex < currentSection.length - 1
                          ? "#555"
                          : "#bbb"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-12">
              <p className="mb-2 fs-18 fw-600">{selectedReview?.packageName}</p>
              <p className="mb-0 fs-16 fw-500 text-muted">
                {selectedReview?.feedback}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Testimonials);
