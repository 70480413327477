import CustomList from "common/CustomList/CustomList";
import { FC } from "react";
import { LandingDataType } from "./LandingUtils";
import PackageStrip from "common/packageStrip/PackageStrip";

const LandingView: FC<{ landingData: LandingDataType }> = ({ landingData }) => {
  return (
    <>
      {landingData.map((item) => {
        if (item.type === "banner")
          return (
            <div key={`${item.title}${item.type}`} className="container">
              <PackageStrip {...item} />
            </div>
          );

        if (item.type === "category_list")
          return <CustomList key={`${item.header}${item.type}`} {...item} />;

        return null;
      })}
    </>
  );
};

export default LandingView;
