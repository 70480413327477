import {
  LandingDataType,
  ReviewType,
  getLandingData,
} from "components/Home/LandingUtils";
import { GetStaticProps, NextPage } from "next";

import SEOWrapper from "common/SEOWrapper";
import withHeaderFooter from "commonHoc/withHeaderFooter";
import Landing from "components/Home/Landing";
import { REVALIDATE } from "constants/Constants";
import { get } from "services/api/apiService";
import { serverLogger } from "utils/Logger";
import Utils from "utils/Utils";

interface HomePageProps {
  landingData: LandingDataType;
  reviews: Array<ReviewType>;
}

const HomePage: NextPage<HomePageProps> = ({ landingData, reviews }) => {
  return (
    <>
      <SEOWrapper keywords="online experiences, travel, yoga classes, vacation, trip, hatha yoga, slim yoga, best travel site, weekend getaways, culinary classes, power yoga, meditation classes online, online yoga classes, places near me to visit, wellness, bike trips, mountain trips" />
      <Landing landingData={landingData} reviews={reviews} />
    </>
  );
};

export const getStaticProps: GetStaticProps<HomePageProps> = async () => {
  const params = {
    page: 1,
    limit: 8,
  };

  const reviews: Array<ReviewType> = [
    {
      name: "Meenal",
      packageName: "Spiti Valley",
      rating: 5,
      imgUrl:
        "https://cdn.bwtexperiences.com/image-bank/review-meenal-kasol_orig.jpeg",
      feedback:
        "It was my first experience with book weekend tours and it was surely damn beautiful. I really had a good company of trip organizers and travelers too. I went solo but came back with lots of friends. It was like fun all the time. It was my best solo trip ever. The whole team was so attentive all the time to help you out. Once again a big thanks to the book weekend tour team. A wonderful journey with wonderful people.",
    },

    {
      name: "Ekta",
      packageName: "Jibhi, Jhalori & Teerthan",
      rating: 5,
      imgUrl:
        "https://cdn.bwtexperiences.com/image-bank/review-ekta-jibhi_orig.jpeg",
      feedback:
        "My experience with Book Weekend Tours is absolutely an interesting one. The way the team has worked to add vibrant memories to my travel book is much appreciable. Thanks, book weekend tours for motivating me to reach to the beautiful Himachal.\nKeep up the good work.",
    },
    {
      name: "Pradyuman & Group",
      packageName: "Kasol & Kheerganga",
      rating: 5,
      imgUrl: "https://cdn.bwtexperiences.com/reviews/pradyuman.jpg",
      feedback:
        "It was a great travel in all meanings. This was my first community trip and indeed a great experience of community trips. Everything was so perfect the offbeat destinations, the less travelled road, that local feel, the community the tour leader. Now I'm a fan of community trips just because of bookweekendtours.com",
    },
    {
      name: "Sharib Sultan",
      packageName: "Chopta & Tunganath",
      rating: 5,
      imgUrl: "https://cdn.bwtexperiences.com/reviews/sharib.jpeg",
      feedback:
        "Most recommended platform for all your travel goals. The best ever planner with awesome experience, new traveller, comfy accommodation and most experienced guide. I met with bestest soul while on journey.",
    },
    {
      name: "Muskaan",
      packageName: "Kasol & Kheerganga",
      rating: 5,
      imgUrl: "https://cdn.bwtexperiences.com/reviews/muskaan.jpg",
      feedback:
        "They are very co-operative and they are good to experience. My first trip was with them to Kasol and I will look after to book some more trips with Book weekend tours\uD83D\uDC4D",
    },
    {
      name: "Sindhoow & Friends",
      packageName: "Macleod & Triund",
      rating: 5,
      imgUrl: "https://cdn.bwtexperiences.com/reviews/sindhoww.jpeg",
      feedback:
        "We went to Dharamshala and Triund. Amazing hosting has been provided by bookweekendtours. We had an Awesome Trip. Thank you so much Book Weekend Tours. Will keep Travelling 😍",
    },
    {
      name: "Qasim & Group",
      packageName: "Chopta & Tunganath",
      rating: 5,
      imgUrl: "https://cdn.bwtexperiences.com/reviews/quasim.jpeg",
      feedback:
        "Thank you guys for making Chopta & Tungnath trip really amazing. Had lot of fun and I captured hundreds of lifelong memories there. Will contact you you soon for my next trip. :)",
    },
  ];

  try {
    const [landingData, moreReviews] = (await Promise.all([
      getLandingData(params),
      get("v1/ratings/experiences/").then(({ data }) =>
        Utils.arrayCheck(data).map((review) => ({
          name: review?.user?.name,
          packageName: review?.package?.name,
          rating: review?.experience_rating,
          imgUrl: review?.user?.profile_pic,
          feedback: review?.experience_review,
        }))
      ),
    ])) as [LandingDataType, Array<ReviewType>];

    return {
      props: {
        landingData,
        reviews: [...reviews, ...moreReviews],
      },
      revalidate: REVALIDATE,
    };
  } catch (e) {
    serverLogger(e);
    return {
      props: {
        landingData: [],
        reviews,
      },
      revalidate: REVALIDATE,
    };
  }
};

export default withHeaderFooter(HomePage);
