import { FC, memo } from "react";
import Utils from "utils/Utils";

const VPArr = [
  {
    url: Utils.cdnUrl("webImages/icons/27_7+On+Trip+assistance.svg"),
    title: "24x7 Assistance",
  },
  {
    url: Utils.cdnUrl("webImages/icons/35%2B+Destinations+to+choose+from.svg"),
    title: "Amazing Experiences",
  },
  {
    url: Utils.cdnUrl("webImages/icons/Best+prices+%26+offers.svg"),
    title: "Unbeatable Prices with Best Offers",
  },
  {
    url: Utils.cdnUrl("webImages/icons/Certified+trip+leaders.svg"),
    title: "Certified Professionals",
  },
  {
    url: Utils.cdnUrl("webImages/icons/Exceptional+Itineraries.svg"),
    title: "Exceptional & Curated Itineraries",
  },
  {
    url: Utils.cdnUrl("webImages/icons/Trips+for+every+weekend.svg"),
    title: "Experience for every day",
  },
];

const ValuePropsStrip: FC<{
  customArray?: Array<any>;
  heading?: string;
  show: boolean;
}> = (props) => {
  if (!props.show) return <></>;
  const flexDiv = (it) => (
    <div key={it.title} className="col-4 col-md-2 mb-5 mb-md-0 text-center">
      {it?.url && (
        <img
          src={it.url}
          alt={it.title}
          width={40}
          height={40}
          loading={"lazy"}
          className="mx-auto d-block mb-4 img-fluid"
        />
      )}
      {it?.icon && it?.icon}
      <p className="mb-0 fs-14 fw-500 text-center">{it.title}</p>
    </div>
  );
  return (
    <div className="jumbotron jumbotron-fluid bg-transparent py-5">
      <div className="container">
        <div className="row align-items-start justify-content-center">
          <div className="col-12">
            <h1 className="commonHeader text-center mb-5">
              {props.heading || "What we promise?"}
            </h1>
          </div>
          {props.customArray
            ? props.customArray.map((it) => flexDiv(it))
            : VPArr.map((it) => flexDiv(it))}
        </div>
      </div>
    </div>
  );
};

export default memo(ValuePropsStrip);
