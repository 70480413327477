import Link from "next/link";
import Utils from "utils/Utils";
import { memo } from "react";
import styles from "./PackageStrip.module.scss";

interface PackageStripProps {
  image: string;
  title: string;
  description: string;
  linkText: string;
  linkUrl: string;
}

const PackageStrip: React.FC<PackageStripProps> = ({
  image,
  title,
  description,
  linkText,
  linkUrl,
}) => (
  <div className={styles.strip}>
    <div className={styles["image-container"]}>
      <img
        className={"img-fluid"}
        src={Utils.constructImageUrl("800x400", "jpeg", image)}
        alt={title}
        loading="lazy"
      />
      <div
        className={`${styles.after} d-flex flex-column align-items-center justify-content-center`}
      >
        {title && <h1 className={"text-white display-4 fw-600"}>{title}</h1>}
        <p className={"text-white lead fw-500 text-center"}>{description}</p>

        <Link href={linkUrl}>
          <a
            className="btn callToAction"
            rel="noopener noreferrer"
            target={"_blank"}
          >
            {linkText}
          </a>
        </Link>
      </div>
    </div>
  </div>
);

export default memo(PackageStrip);
