import CardSlider from "common/CardSlider/CardSlider";
import { ChevronRight } from "common/Icons/Icons";
import ExperienceCard from "../Cards/ExperienceCard/ExperienceCard";
import ImageOverlayCard from "../Cards/ImageOverlayCard";
import Link from "next/link";
import { memo } from "react";

const CustomList = (props) => {
  const { header, locationCard, data, link } = props;
  return (
    <div className="container my-5">
      <h1 className="commonHeader fs-22" style={{ letterSpacing: "0.5px" }}>
        {header}&nbsp;
        {link && (
          <span>
            <Link href={link}>
              <a className={"primaryText fw-600 fs-14"}>
                View All
                <ChevronRight height={20} width={20} />
              </a>
            </Link>
          </span>
        )}
      </h1>
      <CardSlider id={header} length={data.length}>
        {locationCard ? (
          <div className="d-flex">
            {Array.isArray(data) &&
              data?.length &&
              data.map((item) => (
                <div key={item?.slug} className="col-md-3 col-10 px-2">
                  <Link href={`/discover/locations/${item?.slug}`}>
                    <a>
                      <ImageOverlayCard item={item} />
                    </a>
                  </Link>
                </div>
              ))}
          </div>
        ) : (
          <div className="d-flex">
            {Array.isArray(data) &&
              data?.length &&
              data.map((it) => (
                <div key={it.id} className="px-2">
                  <ExperienceCard package={it} />
                </div>
              ))}
          </div>
        )}
      </CardSlider>
    </div>
  );
};

export default memo(CustomList);
