import GooglePlayStoreButton from "common/buttons/GooglePlayStoreButton";
import { memo } from "react";
import styles from "./Landing.module.scss";

const Cover = () => (
  <div className={styles.landingCover}>
    <div className="container">
      <div className="row ">
        <div className="col-md-10 offset-md-1">
          <h1 className={"fw-600 display-4 mb-0"}>
            Discover <span className={"primaryText"}>exotic experiences</span>{" "}
            around the globe
          </h1>
          <div className="d-flex align-items-center justify-content-center mt-2">
            <GooglePlayStoreButton height={100} />
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default memo(Cover);
