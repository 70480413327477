import querystring from "querystring";
import { getByQueryParam } from "services/api/apiService";
import Utils from "utils/Utils";

interface LandingConfigTypeBanner {
  type: "banner";
  title: string;
  description: string;
  linkText: string;
  linkUrl: string;
  image: string;
}

interface LandingConfigTypeCategoryList {
  type: "category_list";
  url: string;
  link?: string;
  locationCard?: true;
  header: string;
  params: querystring.ParsedUrlQueryInput;
}

interface CategoryListType {
  type: "category_list";
  data: Object;
}

export interface ReviewType {
  name: string;
  packageName: string;
  imgUrl: string;
  feedback: string;
  rating: number;
}

export type LandingDataType = Array<
  LandingConfigTypeBanner | (CategoryListType & LandingConfigTypeCategoryList)
>;

export const LandingConfig: Array<
  LandingConfigTypeBanner | LandingConfigTypeCategoryList
> = [
  {
    type: "banner",
    title: "Start yoga today",
    description:
      "You don't need to be flexible or strong to start yoga. All you need is the desire to learn and the willingness to try.",
    linkText: "Start Exploring",
    linkUrl: "/discover/online-experiences/yoga",
    image:
      "https://cdn.bwtexperiences.com/image-bank/chakrata-traveldevils-weekendtravels-4_orig.jpg",
  },

  // {
  //   type: "category_list",
  //   url: "v1/packages/",
  //   link: "/discover/travel/weekend-trips",
  //   header: "Best in Weekend Getaways",
  //   params: {
  //     categories: "weekend-trips",
  //   },
  // },
  // {
  //   type: "banner",
  //   title: "Adventure Escapades",
  //   description:
  //     "Explore exotic experiences in Adventure Escapades starting from ₹14,999 only at BWT Experiences.",
  //   linkText: "Start Exploring",
  //   linkUrl: "/discover/travel/adventure-escapades",
  //   image:
  //     "https://img.bwtexperiences.com/unsafe/1200x450/filters:format(jpeg):quality(100)/https://cdn.bwtexperiences.com/image-bank/gulmarg-himachal-wanderon-skiing-5_orig.jpg",
  // },
  // {
  //   type: "category_list",
  //   url: "v1/packages/",
  //   link: "/discover/travel/work-from-anywhere",
  //   header: "Work from anywhere",
  //   params: {
  //     categories: "work-from-anywhere",
  //   },
  // },

  {
    type: "category_list",
    url: "v1/packages/",
    link: "/discover/online-experiences/yoga",
    header: "Experiences in Yoga",
    params: {
      categories: "yoga",
    },
  },
  // {
  //   type: "category_list",
  //   url: "v1/locations/",
  //   link: "/discover/locations",
  //   locationCard: true,
  //   header: "Explore top destinations",
  //   params: {
  //     best_selling: true,
  //   },
  // },
];

export const getLandingData = async (
  params: querystring.ParsedUrlQueryInput,
  token?: string
) => {
  return Promise.all(
    LandingConfig.map((item) => {
      if (item.type === "category_list")
        return getByQueryParam(
          item.url,
          {
            ...params,
            ...item.params,
            // TODO: Migrate locations api to pagination_v2 as well.
            use_pagination_v2: !item.locationCard,
          },
          { ...(token && { Authorization: token }) }
        ).then(({ data }) => ({
          ...item,
          data: Utils.arrayCheck(item.locationCard ? data : data?.data),
        }));

      return new Promise((resolve) => resolve(item));
    })
  );
};
