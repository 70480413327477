import * as debounce from "debounce";

import { ChevronLeft, ChevronRight } from "../Icons/Icons";

import { Component } from "react";
import PropTypes from "prop-types";

class CardSlider extends Component {
  state = {
    rightArrow: true,
    leftArrow: true,
  };

  cardContainer = null;
  slideLeft = null;
  slideRight = null;

  componentDidMount() {
    const { hideArrowIfNotRequired, length } = this.props;
    this.onClick();
    hideArrowIfNotRequired && length > 4 && this.addScrollEvent();
  }

  onScroll = (e) => {
    this.delayedGetter.clear();
    this.delayedGetter(e);
  };

  scrollEvent = (e) => {
    const { target: { scrollLeft, scrollWidth, offsetWidth } = {} } = e;

    if (scrollLeft === 0) this.setState({ leftArrow: false });
    else this.setState({ leftArrow: true }, this.onClick);

    if (scrollWidth - scrollLeft <= offsetWidth)
      this.setState({ rightArrow: false });
    else this.setState({ rightArrow: true }, this.onClick);
  };

  delayedGetter = debounce(this.scrollEvent, 100);

  addScrollEvent = () => {
    this.setState({ leftArrow: false });
    this.cardContainer.addEventListener("scroll", this.onScroll);
  };

  componentWillUnmount() {
    this.cardContainer.removeEventListener("scroll", this.onScroll);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.length !== this.props.length) this.onClick();
  }

  onClick = () => {
    const front = this.slideRight;
    if (front) {
      front.onclick = () => {
        const container = this.cardContainer;
        sideScroll(container, "right", 5, 300, 10);
      };
    }
    const back = this.slideLeft;
    if (back) {
      back.onclick = () => {
        const container = this.cardContainer;
        sideScroll(container, "left", 5, 300, 10);
      };
    }

    const sideScroll = (element, direction, speed, distance, step) => {
      let scrollAmount = 0;
      const slideTimer = setInterval(() => {
        if (direction === "left") {
          element.scrollLeft -= step;
        } else {
          element.scrollLeft += step;
        }
        scrollAmount += step;
        if (scrollAmount >= distance) {
          clearInterval(slideTimer);
        }
      }, speed);
    };
  };

  showLeftArrow = () => {
    const { leftArrow } = this.state;
    const { hideArrowIfNotRequired } = this.props;
    return !hideArrowIfNotRequired || leftArrow;
  };

  showRightArrow = () => {
    const { rightArrow } = this.state;
    const { hideArrowIfNotRequired } = this.props;
    return !hideArrowIfNotRequired || rightArrow;
  };

  render() {
    const { length, children, doNotStretch, className } = this.props;

    const lengthCheck = length >= 4;

    return (
      <>
        <style jsx global>{`
          .card-carousel-arrow {
            position: absolute;
            top: 35%;
            height: 35px;
            width: 35px;
            z-index: 1;
            border-radius: 50%;
            padding: 5px;
            background: #fff;
            cursor: pointer;
          }

          .card-carousel-arrow.left {
            left: -10px;
          }

          .card-carousel-arrow.right {
            right: -15px;
          }

          .card-carousel {
            width: 100%;
            overflow-x: auto;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none;
          }

          .card-carousel::-webkit-scrollbar {
            display: none;
          }

          .card-carousel .card {
            width: 200px;
          }

          .card-carousel-100 .card {
            width: 100%;
          }

          @media (max-width: 568px) {
            .card-carousel-arrow {
              top: 30%;
            }

            .card-carousel .card {
              width: 150px;
            }

            .card-carousel-100 .card {
              width: 320px;
            }
          }
        `}</style>
        <div className="position-relative">
          {lengthCheck && this.showLeftArrow() && (
            <div
              ref={(el) => (this.slideLeft = el)}
              className={`card-carousel-arrow left shadow`}
            >
              <ChevronLeft />
            </div>
          )}
          <div>
            <div
              ref={(el) => (this.cardContainer = el)}
              className={`${
                lengthCheck || doNotStretch
                  ? "card-carousel"
                  : "card-carousel-100"
              } ${className}`}
            >
              {children}
            </div>
          </div>
          {lengthCheck && this.showRightArrow() && (
            <div
              ref={(el) => (this.slideRight = el)}
              className={`card-carousel-arrow right shadow`}
            >
              <ChevronRight />
            </div>
          )}
        </div>
      </>
    );
  }
}

CardSlider.propTypes = {
  id: PropTypes.string.isRequired,
  length: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default CardSlider;
