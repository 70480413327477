import { NumberStripProps, NumberStripType } from "./types";

type NumberBoxProps = {
  strip: NumberStripType;
};

const NumberBox = ({ strip }: NumberBoxProps) => (
  <div
    className="col text-center border border-dark bg-gradient-2 p-4 br-8"
    style={{
      boxShadow: "10px 12px 15px 0px rgba(0,0,0,0.82)",
    }}
  >
    <h1 className="fw-600">{strip.insight}</h1>
    <div className="fw-500 fs-20 text-green text-dark">{strip.icon}</div>
  </div>
);

const NumberStrip = ({ data }: NumberStripProps) => {
  return (
    <div className="row gap-4">
      {data.map((item) => (
        <NumberBox key={item.insight} strip={item} />
      ))}
    </div>
  );
};
export default NumberStrip;
